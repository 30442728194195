import React from 'react'
import Home from './Components/Home/Home'
import SearchResult from './Components/SearchResult'
import ProductInformation from './Components/ProductInformation/ProductInformation'
import Product from './Components/Product/Product'
import * as AppConstants from './Components/Utilities/AppConstants'
import { bikesCategories } from './Components/Utilities/AppConstants'
import ReactGA from 'react-ga';
import ThankYou from './Components/ThankYou/ThankYou'
/*ReactGA.initialize('-'); //UA-164521515-1*/

const routes = {
  '/': () => <Home />,
  '/products/search': () => <SearchResult />,
  '/products/:category': ({ category }) => {
      // googleAnalytics={ReactGA}
      let bikeCategory = Object.values(AppConstants.bikesCategories).find(bikesCategory => {
          const decodedId = decodeURIComponent(bikesCategory.id.trim().toLowerCase());
          const decodedCategory = decodeURIComponent(category.trim().toLowerCase());
          return decodedId === decodedCategory;
      });
      if (bikeCategory) {
          // If a matching category is found, render the Product component
          return <Product productCategory={bikeCategory} />;
      } else {
          // If no matching category is found, handle the case appropriately
          console.log('No matching category found');
      }
  },

  // '/product/:id': ({ id }) => <ProductInformation productId={id} />,
  '/products/:category/:id': ({ category, id }) => {
    //googleAnalytics={ReactGA}
    let bikeCategory = Object.values(AppConstants.bikesCategories).find(bikesCategory => {
      const decodedId = decodeURIComponent(bikesCategory.id.trim().toLowerCase());
      const decodedCategory = decodeURIComponent(category.trim().toLowerCase());
      return decodedId === decodedCategory;
    });
    let mainCategory;
    if (bikeCategory) {
      console.log(bikeCategory.id);
      
      if (bikeCategory.id.toLowerCase() === 'accessoires') {
        mainCategory = 'accessoires';
      } else {
        mainCategory = 'fietsen';
      }
      category = decodeURIComponent(category);

      return <ProductInformation  productId={id} productCategory={category} mainCategory={mainCategory}/>;
    }
  },

  '/thankyou/:formType':({formType})=> {

    const isAvailable = AppConstants.formTypes.filter((formTypeApp)=>formTypeApp === formType);
    if(formType == 'contactUs')
    {
      isAvailable.push(true)
    }
    if(isAvailable.length === 0)
    {
      return;
    }
    return <ThankYou formType={formType} /> //googleAnalytics={ReactGA}
  }
}
export default routes
