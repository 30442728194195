import React from 'react'

// Labels Naming Section
export const accessories = 'Accessoires'

export const bikesCategories = {
  kidsNonElectric: {
    id: 'Kids tot 12 Jaar Niet Elektrisch',
    name: 'Kids tot 12 Jaar Niet Elektrisch',
    img: 'icons/Bikes/kidsNonElectric.jpg'
  },
  youthNonElectric: {
    id: 'Jongeren & Jeugdfietsen Niet Elektrisch',
    name: 'Jongeren & Jeugdfietsen Niet Elektrisch',
    img: 'icons/Bikes/youthNonElectric.jpg'
  },
  youthEBike: {
    id: 'Jongeren & Jeugdfietsen E-Bike 25Km-h',
    name: 'Jongeren & Jeugdfietsen E-Bike 25Km/h',
    img: 'icons/Bikes/eBike.png'
  },
  classicNonElectric: {
    id: 'Klassiek & Hybride Niet Elektrisch',
    name: 'Klassiek & Hybride Niet Elektrisch',
    img: 'icons/Bikes/classicNonElectric.png'
  },
  classicEBike: {
    id: 'Klassiek & Hybride E-Bike 25Km-h',
    name: 'Klassiek & Hybride E-Bike 25Km/h',
    img: 'icons/Bikes/eBike.png'
  },
  lightweightNonElectric: {
    id: 'Lichtgewicht Niet Elektrisch',
    name: 'Lichtgewicht Niet Elektrisch',
    img: 'icons/Bikes/lightWeightNonElectric.jpg'
  },
  lightweightEBike: {
    id: 'Lichtgewicht E-Bike 25Km-h',
    name: 'Lichtgewicht E-Bike 25Km/h',
    img: 'icons/Bikes/eBike.png'
  },
  longtailEBike: {
    id: 'Longtail E-Bike 25Km-h',
    name: 'Longtail E-Bike 25Km/h',
    img: 'icons/Bikes/longtail.jpeg'
  },
  foldingEBike: {
    id: 'Vouwfietsen & Minifietsen E-Bike 25Km-h',
    name: 'Vouwfietsen & Minifietsen E-Bike 25Km/h',
    img: 'icons/Bikes/vouwBike.png'
  },
  cargoEBike: {
    id: 'E-Cargo en Kidsvervoer E-Bike 25Km-h',
    name: 'E-Cargo en Kidsvervoer E-Bike 25Km/h',
    img: 'icons/Bikes/cargoEBike.jpeg'
  },
  speedPedelec: {
    id: 'Speed Pedelec E-Bike 45Km-h',
    name: 'Speed Pedelec E-Bike 45Km/h',
    img: 'icons/Bikes/speedPedelec.jpg'
  },
  mountainNonElectric: {
    id: 'ATB - Mountainbike Niet Elektrisch',
    name: 'ATB - Mountainbike Niet Elektrisch',
    img: 'icons/Bikes/mountainNonElectric.jpg'
  },
  mountainEBike: {
    id: 'ATB - Mountainbike E-Bike 25Km-h',
    name: 'ATB - Mountainbike E-Bike 25Km/h',
    img: 'icons/Bikes/mountainBike.png'
  },
  raceNonElectric: {
    id: 'Racefietsen Niet Elektrisch',
    name: 'Racefietsen Niet Elektrisch',
    img: 'icons/Bikes/raceNonElectric.jpg'
  },
  raceEBike: {
    id: 'Racefietsen E-Bike 25Km-h',
    name: 'Racefietsen E-Bike 25Km/h',
    img: 'icons/Bikes/raceBike.png'
  },
  gravelNonElectric: {
    id: 'Gravelbikes Niet Elektrisch',
    name: 'Gravelbikes Niet Elektrisch',
    img: 'icons/Bikes/gravelNonElectric.jpg'
  },
  gravelEBike: {
    id: 'Gravelbikes E-Bike 25Km-h',
    name: 'Gravelbikes E-Bike 25Km/h',
    img: 'icons/Bikes/mountainBike.png'
  },
  accessoires: {
    id: 'accessoires',
    name: 'Accessoires',
    img: 'icons/Bikes/accessories.png'
  },
};
/*
export const expertEmails = {
  eBike: 'rik@purplepanda.be',
  raceBike: 'rik@purplepanda.be',
  cityBike: 'rik@purplepanda.be',
  vouwBike: 'rik@purplepanda.be',
  mountainBike: 'rik@purplepanda.be',
  accessoires: 'rik@purplepanda.be',
}
*/
export const filterOptions = {
  Type: {
    name: 'type',
    title: 'Type',
    options: [
      "Sloten",
      "Kinderkarren",
      "Helmen",
      "Pompen & Drukmeters",
      "Kleding",
      "Tassen & Manden",
      "Fietsverzorging & Onderhoud",
    ],
  },
  Speed: {
    name: 'speed',
    title: 'Snelheid',
    options: ['45 km/u', '25 km/u', 'Zonder elektrische ondersteuning'],
  },
  Brand: {
    name: 'brand',
    title: 'Merk',
    'Kids tot 12 Jaar Niet Elektrisch': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Jongeren & Jeugdfietsen Niet Elektrisch': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Jongeren & Jeugdfietsen E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Klassiek & Hybride Niet Elektrisch': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Klassiek & Hybride E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Lichtgewicht Niet Elektrisch': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Lichtgewicht E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Longtail E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Vouwfietsen & Minifietsen E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'E-Cargo en Kidsvervoer E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Speed Pedelec E-Bike 45Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'ATB - Mountainbike Niet Elektrisch': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'ATB - Mountainbike E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Racefietsen Niet Elektrisch': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Racefietsen E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Gravelbikes Niet Elektrisch': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Gravelbikes E-Bike 25Km-h': ["Gazelle", "Scott","Oxford","Koga","Das.O. E-Bike","Advanced E-Bike","Granville","Bizobike","BNB","BFK","Diversen"],
    'Accessoires':['Scott', 'Koga', 'Bizobike', 'Gazelle'],
  },  
  Price: {
    name: 'price',
    title: 'Prijs',
    options: ['minPrice', 'maxPrice'],
  },
  Usage: {
    name: 'usage',
    title: 'Gebruik',
    options: ['Woon-werk', 'Hobby', 'Pro', 'Vrije tijd'],
  },
}

//End Labels Naming Section

// Env Constants
export const frontEndEnvURL = process.env.PUBLIC_URL
export const backEndEnvURL =
  process.env.NODE_ENV === 'development'
    ? 'https://voorstel-fietsenmintjensb2b.be/'
    : 'https://fietsenmintjens-backend.herokuapp.com/'

//End Env Constants

// Random App Constants
export const formTypes = ["bestelnu","proefrit"]


// End Random App Constants

/*
//Images Section
export const eBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/eBike.png`}
      alt={'E-Bike  Not Found'}
    />
  )
}

export const raceBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/raceBike.png`}
      alt={'Sport Bike  Not Found'}
    />
  )
}

export const cityBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/cityBike.png`}
      alt={'City Bike  Not Found'}
    />
  )
}

export const mountainBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/mountainBike.png`}
      alt={'Mountain Bike Image  Not Found'}
    />
  )
}

export const vouwBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/vouwBike.png`}
      alt={'Vouw Bike Image  Not Found'}
    />
  )
}
export const kidsBikeImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/kidsBike.jpg`}
      alt={'Kids Image Bike  Not Found'}
    />
  )
}
export const accessoriesImage = () => {
  return (
    <img
      src={`${frontEndEnvURL}/icons/Bikes/accessories.png`}
      alt={'Accessories Image  Not Found'}
    />
  )
}
export const gazelleImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/Gazelle.png`}
      alt={'Gazelle Image  not found'}
    />
  )
}
export const scottImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/Scott.png`}
      alt={'Scott Image  not found'}
    />
  )
}
export const bizoBikeImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/bizobike.png`}
      alt={'Bizo Bike Image  not found'}
    />
  )
}
export const kogaImage = () => {
  return (
    <img
      style={{ width: '160px', height: '72px' }}
      src={`${frontEndEnvURL}/icons/HomePage/koga.png`}
      alt={'Koga Image  not found'}
    />
  )
}
//End Images Section
*/
export const categoryImage = ({ category }) => {
  if (!category || !category.img) {
    return <p>Image not available</p>;
  }

  return (
    <img
      style={{ width: '50px', height: '30px', objectFit: 'contain' }}
      src={`${frontEndEnvURL}/${category.img}`}
      alt={`${category.name} - bike picture`}
    />
  );
};