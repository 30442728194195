import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Checkbox,
  Col,
  Descriptions,
  Input,
  Row,
  Button,
} from 'antd'
import { navigate, useQueryParams } from 'hookrouter'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import * as FietsenMintjensAPI from '../../api/FietsenMintjensAPI'
import * as appConstants from '../Utilities/AppConstants'

import Container from '../Common/Container/ContainerComponent'
import ImageButtonComponent from '../Utilities/ImageButtonComponent/ImageButtonComponent'
import BikeListComponent from '../Utilities/BikeComponent/BikeListComponent'

import './Product.css'
import * as ProductStyle from './Product.module.scss'
import ReactGA from 'react-ga'
import ReactPlayer from 'react-player'
import VideoTextComponent from '../Utilities/VideoTextComponent/VideoTextComponent'
import ImgTextComponent from '../Utilities/ImgTextComponent/ImgTextComponent'
import { frontEndEnvURL } from '../Utilities/AppConstants';
import * as AppConstants from '../Utilities/AppConstants'

import App from '../../App'

export default function Product(props) {
//  props.googleAnalytics.pageview(`products/${props.productCategory.id}`);
  // This what the api should at least respond with
  const [productsData, setProductsData] = useState([])
  const [state, setState] = useState({})
  const [queryParams,setQueryParams] = useQueryParams()
  const [isLoading,setLoading] = useState(true);
  const bikeCompGridStyle = {
    gutter: 16,
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3,
  }

  useEffect(()=>{
    setProductsData([])
 //   setQueryParams({},true)
    setState({})
    let params = new URLSearchParams(window.location.search)
    for (let p of params) {
      let keyObject = {};
      if(p[0]=== "merk"){
        keyObject[`brand`] = [p[1]];
        setState({ ...state,...keyObject})
      }

    }
  },[window.location.href])

  useEffect(() => {
    FietsenMintjensAPI.getProductsByCategory(props.productCategory.id, state)
      .then(res => {
        if (!_.isEqual(productsData, res.data)) {
          setProductsData(res.data)
        }
        setLoading(false);
      })
      .catch(fail => console.log(fail))
  }, [productsData, props.productCategory.id, state])

  useEffect(() => {
    const { prevQueryParams } = state
    if (queryParams.key && !_.isEqual(prevQueryParams, queryParams)) {
      console.log('state', state)
      setState({
        ...state,
        [queryParams.key]: [queryParams.value],
        prevQueryParams: queryParams,
      })
    }
  }, [state, queryParams])



  const [filterPanelVisible, setFilterPanelVisible] = useState(false)

  const styles = {/*
    filterSnelheidOptionPanel: {
      opacity: 0,
      animation: '1s appear forwards',
      animationDelay: '0.1s',
    },
    filterMerkOptionPanel: {
      opacity: 0,
      animation: '1s appear forwards',
      animationDelay: '0.2s',
    },*/
    filterPrijsOptionPanel: {
      opacity: 0,
      animation: '1s appear forwards',
      animationDelay: '0.3s',
    },/*
    filterGebruikOptionPanel: {
      opacity: 0,
      animation: '1s appear forwards',
      animationDelay: '0.4s',
    },*/
  }

  const handleFilterMenuBtnClick = () => {
    setFilterPanelVisible(!filterPanelVisible)
  }

  const handleMoreInfoBtnClick = id => {
    navigate(`/products/${props.productCategory.id}/${id}`)
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0)
    }
  }

  const handleChangeTextDebounce = debounce((str, param) => {
    setLoading(true)
    setState({ ...state, [param]: str })
  }, 500)

  const handleCheckBoxChange = (param, value) => e => {
    setLoading(true)
    let currentValue = state[param]
    setQueryParams({},true)
    if (e.target.checked) {
      if (!currentValue) {
        currentValue = [value]
      } else {
        currentValue.push(value)
      }
    } else {
      if (currentValue) {
        const currentIndex = currentValue.findIndex(item => item === value)
        currentValue.splice(currentIndex, 1)
      }
    }
    setState({ ...state, [param]: currentValue })
    console.log({ ...state, [param]: currentValue })
  }
  const getVideoUrl = ()=>{
    /*switch(props.productCategory.name){
      case AppConstants.bikesCategories.eBike.name:
        return 'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/E-bikes.mp4';
      case AppConstants.bikesCategories.cityBike.name:
        return 'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/stads+%3A+vrijetijdsfietsen.mp4';
      case AppConstants.bikesCategories.mountainBike.name:
        return 'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/Mountainbikes.mp4';
      case AppConstants.bikesCategories.kidsBike.name:
        return 'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/Kinderfietsen.mp4';
      case AppConstants.bikesCategories.vouwBike.name:
        return 'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/Vouwfietsen.mp4';
      case AppConstants.bikesCategories.raceBike.name:
        return 'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/Racefietsen.mp4';
    }*/
   return 'https://fietsenmintjens.s3.eu-central-1.amazonaws.com/Videos/E-bikes.mp4';
  }

  const getImgUrl = ()=>{
    switch (props.productCategory.name) {
      case AppConstants.bikesCategories.accessoires.name:
        return `${frontEndEnvURL}/icons/Bikes/accessories.png`;
      case AppConstants.bikesCategories.kidsNonElectric.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Kids tot 12 Jaar Niet Electrisch.jpg`;
      case AppConstants.bikesCategories.youthNonElectric.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Jongeren en Jeugdfietsen E-Bike Electrisch 25Kmh.jpg`;
      case AppConstants.bikesCategories.youthEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Jongeren en Jeugdfietsen E-Bike Electrisch 25Kmh.jpg`;
      case AppConstants.bikesCategories.classicNonElectric.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Klassiek en Hybride Niet Electrisch.jpg`;
      case AppConstants.bikesCategories.classicEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Klassiek en Hybride E-Bike 25Kmh.jpg`;
      case AppConstants.bikesCategories.lightweightNonElectric.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Lichtgewicht Niet Electrisch.jpg`;
      case AppConstants.bikesCategories.lightweightEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Lichtgewicht E-Bike 25Kmh.jpg`;
      case AppConstants.bikesCategories.longtailEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Longtail E-Bike 25Kmh.jpg`;
      case AppConstants.bikesCategories.foldingEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Vouwfietsen en Minifietsen E-Bike 25Kmh.jpg`;
      case AppConstants.bikesCategories.cargoEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/E-Cargo en Kidsvervoer E-Bike 25 Kmh.jpeg`;
      case AppConstants.bikesCategories.speedPedelec.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Speed Pedelec E-Bike 45 Kmh.jpeg`;
      case AppConstants.bikesCategories.mountainNonElectric.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/ATB-Mountainbike Niet Electrisch.jpg`;
      case AppConstants.bikesCategories.mountainEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/ATB-Mountainbike E-Bike 25 Kmh.jpeg`;
      case AppConstants.bikesCategories.raceNonElectric.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Racefietsen Niet Electrisch.jpg`;
      case AppConstants.bikesCategories.raceEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Racefietsen E-Bike 25Kmh.webp`;
      case AppConstants.bikesCategories.gravelNonElectric.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Gravelbikes E-Bike 25Kmh.jpg`;
      case AppConstants.bikesCategories.gravelEBike.name:
        return `${frontEndEnvURL}/icons/Bikes-sfeer/Gravelbikes E-Bike 25Kmh.jpg`;
      default:
        return `https://voorstel-fietsenmintjensb2b.be/maak_keuze.jpg`;
    }  
  }

  const getBodyTekst = ()=>{
    switch (props.productCategory.name) {
      case AppConstants.bikesCategories.accessoires.name:
        return "Al onze accessoires vind je hier.";
      case AppConstants.bikesCategories.kidsNonElectric.name:
        return "Onze fietsen zijn stevig, veilig en volledig afgestemd op de noden van jonge fietsers. Kies uit vrolijke kleuren, coole ontwerpen en betrouwbare modellen die tegen een stootje kunnen. Zo beleven alle kinderen eindeloos fietsplezier en kunnen alle ouders met een gerust hart toekijken.";
      case AppConstants.bikesCategories.youthNonElectric.name:
        return "Op zoek naar een sportieve en betrouwbare fiets om naar school te rijden, vrienden te ontmoeten of gewoon lekker rond te cruisen? Bij Fietsen Mintjens hebben we een ruim aanbod aan stevige, trendy en comfortabele fietsen die perfect zijn aangesloten bij de actieve levensstijl van jongeren. <br><br>Onze niet-elektrische fietsen combineren stijl met kwaliteit, zodat je zorgeloos en vol vertrouwen de weg op kunt. Kies uit hippe designs, soepele versnellingen en robuuste frames die tegen een stootje kunnen.";
      case AppConstants.bikesCategories.youthEBike.name:
        return "Wil je vlot naar school of naar vrienden van je sportclub zonder bezweet aan komen? Met een elektrische fiets van Fietsen Mintjens combineer je snelheid, gemak en stijl. Of je nu lange afstanden fietst of gewoon sneller wilt zijn dan de anderen, onze E-Bikes voor jongeren geven je die extra boost wanneer je het nodig hebt.<br><br>Onze elektrische fietsen zijn hip, betrouwbaar en speciaal ontworpen voor jonge rijders. Met krachtige motoren, duurzame batterijen en coole designs ben je altijd in beweging, zonder moeite, maar met maximaal plezier!";
      case AppConstants.bikesCategories.classicNonElectric.name:
        return "<b>Geniet van elke rit, zonder gedoe!</b><br><br>Soms is gewoon fietsen het fijnste wat er is. Of je nu door de stad rijdt, naar het werk fiets of een ontspannen tocht maakt? Onze niet-elektrische fietsen bieden comfort, stijl en betrouwbaarheid.<br><br>Sterk, soepel en onderhoudsvriendelijk, precies wat je nodig hebt voor dagelijks fietsplezier.";
      case AppConstants.bikesCategories.classicEBike.name:
        return "Wil je vlot naar het werk, de stad in of gewoon ontspannen genieten van elke rit? Met een E-Bike van Fietsen Mintjens fiets je steeds als vanzelf.<br><br>Onze elektrische fietsen combineren kracht, comfort en stijl, zodat je altijd met een glimlach op pad gaat. Kies voor gemak, bereik en vooral heel veel rijplezier.<br><br><b>Stap op en ervaar de vrijheid van elektrische fietsen!</b>";
      case AppConstants.bikesCategories.lightweightNonElectric.name:
        return "Fiets met gemak en vrijheid op een lichtgewicht model dat soepel en vlot reageert. Of je nu door de stad fietst of lange tochten maakt, deze fietsen bieden een lichte, soepele en comfortabele rijervaring.<br><br>Dankzij het doordachte ontwerp en de hoogwaardige afwerking rij je lichter, stuur je gemakkelijker en geniet je extra.";
      case AppConstants.bikesCategories.lightweightEBike.name:
        return "Geniet van het beste van twee werelden: de extra ondersteuning van een E-Bike en het lichte, soepele rijgevoel van een lichtgewicht fiets. Deze lichtgewicht elektrische fietsen maken elke rit moeiteloos, wendbaar en comfortabel.<br><br>Dankzij het slanke ontwerp en de slimme technologie rijd je lichter, accelereer je vlotter en geniet je van stressvrije bewegingsvrijheid.";
      case AppConstants.bikesCategories.longtailEBike.name:
        return "Heb je extra plaats nodig voor kinderen, boodschappen of bagage? Een longtail-fiets biedt de perfecte combinatie van draagkracht, wendbaarheid en rijcomfort. Met een verlengd frame en een stevige constructie vervoer je moeiteloos alles wat je nodig hebt, zonder in te leveren op stabiliteit.<br><br>Of je nu kiest voor een praktische stadsrit of een avontuurlijke reis, onze longtail fietsen maken elke rit soepel en zorgeloos.<br><br><b>Meer ruimte dus meer mogelijkheden!</b>";
      case AppConstants.bikesCategories.foldingEBike.name:
        return "Op zoek naar een slanke, ruimtebesparende oplossing voor je dagelijkse verplaatsingen? Onze vouwfietsen en mini E-Bikes combineren gemak, wendbaarheid en elektrische ondersteuning in een compact formaat. Perfect voor in de trein, in de auto of thuis!<br><br>Met een lichtgewicht ontwerp en krachtige motor fiets je krachtig door de stad en vouw je je fiets in enkele seconden op. Ideaal voor pendelaars en avonturiers die flexibiliteit en weinig ruimte hebben.<br><br><b>Klein van formaat, groots in gemak – ontdek hier jouw ideale vouwfiets of mini E-Bike!</b>";
      case AppConstants.bikesCategories.cargoEBike.name:
        return "Wil je gemakkelijk je kinderen, boodschappen of zware ladingen vervoeren? Ontdek onze E-Cargo fietsen met 25 km/u ondersteuning met gecombineerde kracht, stabiliteit en comfort, zodat je altijd vlot en veilig onderweg bent.<br><br>Met een robuust frame, krachtige motor en doordacht ontwerp fiets je soepel door de stad van het platteland, zelfs met een volle lading. Ideaal voor gezinnen en professionals die efficiënt én milieuvriendelijk zijn.<br><br><b>Meer meenemen, minder moeite – ontdek de kracht van E-Cargo fietsen!</b>";
      case AppConstants.bikesCategories.speedPedelec.name:
        return "Wil je sneller, verder en zonder weerstand naar je bestemming? Met een Speed Pedelec van 45 km/u combineer je de vrijheid van fietsen met de kracht van een E-Bike. Geen tijdverlies meer want jij zoeft moeiteloos voorbij.<br><br>Dankzij de krachtige motor, stabiele wegligging en hoogwaardige afwerking geniet je van dynamisch en efficiënt woon-werkverkeer. Of het nu gaat om lange afstanden of een snelle rit door de stad, met een Speed Pedelec ben je altijd in pole position.<br><br><b>Trap minder, ga harder – ontdek de kracht van meer snelheid!</b>";
      case AppConstants.bikesCategories.mountainNonElectric.name:
        return "Voor wie geen pad te ruig is en geen uitdaging te groot is. Onze niet-elektrische ATB mountainbikes zijn gebouwd voor actie, grip en controle op elk terrein. Of je nu door modderige paden scheurt, rotsachtige afdalingen neemt of vaak singletracks ontdekt. Met deze fietsen sta je steeds paraat.<br><br>Dankzij robuuste frames, uitgebreide vering en krachtige schakelgroepen heb jij de volledige controle, eender welke ondergrond. Dus waar wacht je op?<br><br><b>Ga verder, ga harder – verleg je grenzen op twee wielen!</b>";
      case AppConstants.bikesCategories.mountainEBike.name:
        return "Wil je steilere hellingen beklimmen, ruigere paden verkennen en langer doorgaan zonder grenzen? Met een E-MTB combiner je kracht en uithoudingsvermogen voor het ultieme offroad-avontuur.<br><br>Dankzij de krachtige ondersteuning bedwing je elke klim met gemak en scheur je met volledige controle over ruig terrein. Robuuste frames, uitgebreide vering en weggewerkte motoren zorgen ervoor dat jij altijd een stap voor bent!<br><br><b>Trap minder, geniet meer – ontdek de vrijheid van elektrisch mountainbiken!</b>";
      case AppConstants.bikesCategories.raceNonElectric.name:
        return "Voor wie sneller wil gaan, verder wil rijden en elke rit tot het uiterste wil pushen. Onze racefietsen zijn ontworpen voor snelheid, efficiëntie en aerodynamica, zodat je elke kilometer maximaal beleeft.<br><br>Lichtgewicht frames, soepele versnellingen en een gestroomlijnd ontwerp zorgen voor minimale weerstand en maximale kracht. Of je nu traint, koerst of records breekt, met de juiste fiets haal je het beste in je naar boven.<br><br><b>Sneller, lichter, soepeler – ervaar de ultieme racebeleving!</b>";
      case AppConstants.bikesCategories.raceEBike.name:
        return "Wil je verder, sneller en met meer kracht rijden zonder in te boeten op het pure racegevoel? Onze elektrische racefietsen combineren lichtgewicht prestaties met krachtige ondersteuning, waardoor je krachtig klimt, sneller accelereert en mooiere ritten aankan.<br><br>Dankzij een subtiel geïntegreerde motor en uitgebreide technologie krijg je precies de boost die je nodig hebt, zonder het gevoel van een traditionele racefiets te verliezen. Perfect voor uitdagende ritten, lange toertochten of gewoon die extra kracht.<br><br><b>Meer kilometers, meer snelheid – ervaar de kracht van een E-Racefiets!</b>";
      case AppConstants.bikesCategories.gravelNonElectric.name:
        return "Van asfalt tot grindwegen en modderpaden – met een Gravelbike bepaal jij de route. Deze veelzijdige fietsen combineren snelheid, comfort en avontuur, zodat je moeiteloos schakelt tussen verharde en onverharde wegen.<br><br>Met een licht, sterk frame, bredere banden en een comfortabele geometrie geniet je van stabiliteit en controle, ongeacht de ondergrond. Perfect voor lange reizen, onbekende wegen en pure ontdekkingstochten.<br><br><b>Verleg je grenzen en ontdek nieuwe wegen met een gravelbike!</b>";
      case AppConstants.bikesCategories.gravelEBike.name:
        return "Wil je verder, sneller en met meer gemak nieuwe wegen verkennen? Met een elektrische Gravelbike geniet je van ultieme vrijheid, zowel op asfalt als op ruige paden. De krachtige ondersteuning geeft je net dat extra duwtje, zodat je moeiteloos lang en moeilijk terrein aankan.<br><br>Dankzij een lichtgewicht frame, brede banden en een uitgebreide motorkracht krijg je controle en snelheid op elk oppervlak. Perfect voor avonturiers die geen grenzen willen kennen.<br><br><b>Trap verder, ontdek meer – ervaar de kracht van een E-Gravelbike!</b>";
      default:
        return `Bekijk het aanbod`;
    }
  }

  const getHeadingTekst = ()=>{
    switch (props.productCategory.name) {
      case AppConstants.bikesCategories.foldingEBike.name:
        return "Vouwfietsen & Mini E-Bikes – Compact, krachtig en flexibel!";
      case AppConstants.bikesCategories.cargoEBike.name:
        return "E-Cargo fietsen & Kidsvervoer – Slank, krachtig en zorgeloos op weg!";
      case AppConstants.bikesCategories.speedPedelec.name:
        return "Speed ​​Pedelecs – Kracht, snelheid en pure vrijheid!";
      case AppConstants.bikesCategories.mountainNonElectric.name:
        return "ATB Mountainbikes – Puur avontuur, grenzeloze vrijheid!";
      case AppConstants.bikesCategories.mountainEBike.name:
        return "E-Mountainbikes – Meer kracht, meer avontuur!";
      case AppConstants.bikesCategories.raceNonElectric.name:
        return "Racefietsen – Pure snelheid, maximale prestaties!";
      case AppConstants.bikesCategories.raceEBike.name:
        return "E-Racefietsen – Snelheid met een extra boost!";
      case AppConstants.bikesCategories.gravelNonElectric.name:
        return "Gravelbikes – Vrijheid op elk terrein!";
      case AppConstants.bikesCategories.gravelEBike.name:
        return "E-Gravelbikes – Avontuur met extra kracht!";
      default:
        return `Ons ${props.productCategory.name} aanbod.`;
    }
  }

  return (
    <Container>
      <Row
        gutter={[{ xs: 12, sm: 16, md: 24, lg: 32, xl: 60 }, 20]}
        className={ProductStyle.mainViewContainer}
      >
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <div className={ProductStyle.colHeader}>
            <Breadcrumb>
              <Breadcrumb.Item
                href=""
                onClick={() => {
                  navigate('/')
                }}
              >
                Fietsen
              </Breadcrumb.Item>
              <Breadcrumb.Item>{props.productCategory.name}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row
            gutter={[{ sm: 16, md: 24, lg: 32 }, 20]}
            type="flex"
            className={ProductStyle.filterViewsRow}
          >
            {props.productCategory.id ===
              appConstants.bikesCategories.accessoires.id && (
              <Col xs={24} sm={16} md={16} lg={24}>
                <div className={ProductStyle.filterPanel}>
                  <Descriptions
                    column={{ xs: 1, sm: 2, md: 3, lg: 1 }}
                    title={appConstants.filterOptions.Type.title}
                    colon={false}
                  >
                    {appConstants.filterOptions.Type.options.map((item, i) => (
                      <Descriptions.Item
                        key={i}
                        label={
                          <Checkbox
                            onChange={handleCheckBoxChange(
                              appConstants.filterOptions.Type.name,
                              item
                            )}
                            checked={
                              state[appConstants.filterOptions.Type.name]
                                ? state[
                                    appConstants.filterOptions.Type.name
                                  ].findIndex(el => el === item) >= 0
                                : false
                            }
                          />
                        }
                      >
                        {item}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>
              </Col>
            )}
           {/*{props.productCategory.id === appConstants.bikesCategories.classicEBike.id && (
              <Col xs={24} sm={12} md={6} lg={24}>
                <div className={ProductStyle.filterPanel}>
                  <Descriptions
                    column={1}
                    title={appConstants.filterOptions.Speed.title}
                    colon={false}
                  >
                    {appConstants.filterOptions.Speed.options.map((item, i) => (
                      <Descriptions.Item
                        key={i}
                        label={
                          <Checkbox
                            onChange={handleCheckBoxChange(
                              appConstants.filterOptions.Speed.name,
                              item
                            )}
                            checked={
                              state[appConstants.filterOptions.Speed.name]
                                ? state[
                                    appConstants.filterOptions.Speed.name
                                  ].findIndex(el => el === item) >= 0
                                : false
                            }
                          />
                        }
                      >
                        {item}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>
              </Col>
            )}*/}
            {props.productCategory.id !==
              appConstants.bikesCategories.accessoires.id && (
              <Col
                xs={24}
                sm={8}
                md={8}
                lg={24}
              >
                {/*<div className={ProductStyle.filterPanel}>
                  <Descriptions
                    column={1}
                    title={appConstants.filterOptions.Brand.title}
                    colon={false}
                  >
                    {appConstants.filterOptions.Brand[`${props.productCategory.id}`].map((item, i) => (
                      <Descriptions.Item
                        key={i}
                        label={
                          <Checkbox
                            onChange={handleCheckBoxChange(
                              appConstants.filterOptions.Brand.name,
                              item
                            )}
                            checked={
                              state[appConstants.filterOptions.Brand.name]
                                ? state[
                                    appConstants.filterOptions.Brand.name
                                  ].findIndex(el => el === item) >= 0
                                : false
                            }
                          />
                        }
                      >
                        {item}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>*/}
              </Col>
            )}
            <Col
              xs={24}
              sm={8}
              md={8}
              lg={24}
            >
              <div className={ProductStyle.filterPanel}>
                <Descriptions
                  column={1}
                  title={appConstants.filterOptions.Price.title}
                  colon={false}
                >
                  {appConstants.filterOptions.Price.options.map((item, i) => (
                    <Descriptions.Item
                      key={i}
                      label={
                        <Input
                          placeholder={
                            item === 'minPrice' ? '€. Min' : '€. Max'
                          }
                          onChange={e =>
                            handleChangeTextDebounce(e.target.value, item)
                          }
                        />
                      }
                      className="descriptionInput"
                    />
                  ))}
                </Descriptions>
              </div>
            </Col>
            {props.productCategory.id !==
              appConstants.bikesCategories.accessoires.id && (
              <Col
                xs={24}
                sm={8}
                md={8}
                lg={24}
              >
                {/*<div className={ProductStyle.filterPanel}>
                  <Descriptions
                    column={1}
                    title={appConstants.filterOptions.Usage.title}
                    colon={false}
                  >
                    {appConstants.filterOptions.Usage.options.map((item, i) => (
                      <Descriptions.Item
                        key={i}
                        label={
                          <Checkbox
                            onChange={handleCheckBoxChange(
                              appConstants.filterOptions.Usage.name,
                              item
                            )}
                            checked={
                              state[appConstants.filterOptions.Usage.name]
                                ? state[
                                    appConstants.filterOptions.Usage.name
                                  ].findIndex(el => el === item) >= 0
                                : false
                            }
                          />
                        }
                      >
                        {item}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>*/}
              </Col>
            )}
          </Row>
          <Row className={ProductStyle.filterMenuBar}>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ color: 'red' }}>FILTEREN</span>
              <Button
                type="danger"
                icon="filter"
                style={{ color: 'white' }}
                onClick={() => handleFilterMenuBtnClick()}
              />
            </Col>
          </Row>
          <Row className={'filterPanelForMobileRow'}>
            <Col
              className={ProductStyle.filterPanelForMobile}
              span={24}
              style={{
                height: filterPanelVisible
                  ? props.productCategory.id === appConstants.bikesCategories.classicEBike.id
                    ? 748
                    : props.productCategory.id ===
                      appConstants.bikesCategories.accessoires.id
                    ? 670
                    : 615
                  : 0,
              }}
            >
              {filterPanelVisible && (
                <React.Fragment>
                 {/* {props.productCategory.id ===
                    appConstants.bikesCategories.accessoires.id && (
                    <div
                      className={ProductStyle.filterPanel}
                      style={styles.filterSnelheidOptionPanel}
                    >
                      <Descriptions
                        column={{ xs: 1, sm: 2, md: 3, lg: 1 }}
                        title={appConstants.filterOptions.Type.title}
                        colon={false}
                      >
                        {appConstants.filterOptions.Type.options.map(
                          (item, i) => (
                            <Descriptions.Item
                              key={i}
                              label={
                                <Checkbox
                                  onChange={handleCheckBoxChange(
                                    appConstants.filterOptions.Type.title,
                                    item
                                  )}
                                  checked={
                                    state[appConstants.filterOptions.Type.name]
                                      ? state[
                                          appConstants.filterOptions.Type.name
                                        ].findIndex(el => el === item) >= 0
                                      : false
                                  }
                                />
                              }
                            >
                              {item}
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions>
                    </div>
                  )}*/}
                  {/*{props.productCategory.id ===
                    appConstants.bikesCategories.classicEBike.id && (
                    <div
                      className={ProductStyle.filterPanel}
                      style={styles.filterSnelheidOptionPanel}
                    >
                      <Descriptions
                        column={1}
                        title={appConstants.filterOptions.Speed.title}
                        colon={false}
                      >
                        {appConstants.filterOptions.Speed.options.map(
                          (item, i) => (
                            <Descriptions.Item
                              key={i}
                              label={
                                <Checkbox
                                  onChange={handleCheckBoxChange(
                                    appConstants.filterOptions.Speed.name,
                                    item
                                  )}
                                  checked={
                                    state[appConstants.filterOptions.Speed.name]
                                      ? state[
                                          appConstants.filterOptions.Speed.name
                                        ].findIndex(el => el === item) >= 0
                                      : false
                                  }
                                />
                              }
                            >
                              {item}
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions>
                    </div>
                  )}*/}
                  {/*{props.productCategory.id !==
                    appConstants.bikesCategories.accessoires.id && (
                    <div
                      className={ProductStyle.filterPanel}
                      style={styles.filterMerkOptionPanel}
                    >
                      <Descriptions
                        column={1}
                        title={appConstants.filterOptions.Brand.title}
                        colon={false}
                      >
                        {appConstants.filterOptions.Brand[props.productCategory.id].map(
                          (item, i) => (
                            <Descriptions.Item
                              key={i}
                              label={
                                <Checkbox
                                  onChange={handleCheckBoxChange(
                                    appConstants.filterOptions.Brand.name,
                                    item
                                  )}
                                  checked={
                                    state[appConstants.filterOptions.Brand.name]
                                      ? state[
                                          appConstants.filterOptions.Brand.name
                                        ].findIndex(el => el === item) >= 0
                                      : false
                                  }
                                />
                              }
                            >
                              {item}
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions>
                    </div>
                  )}*/}

                  <div
                    className={ProductStyle.filterPanel}
                    style={styles.filterPrijsOptionPanel}
                  >
                    <Descriptions
                      column={1}
                      title={appConstants.filterOptions.Price.title}
                      colon={false}
                    >
                      {appConstants.filterOptions.Price.options.map(
                        (item, i) => (
                          <Descriptions.Item
                            key={i}
                            label={
                              <Input
                                placeholder={
                                  item === 'minPrice' ? '€. Min' : '€. Max'
                                }
                                onChange={e =>
                                  handleChangeTextDebounce(e.target.value, item)
                                }
                              />
                            }
                            className="descriptionInput"
                          />
                        )
                      )}
                    </Descriptions>
                  </div>
                  {/*{props.productCategory.id !==
                    appConstants.bikesCategories.accessoires.id && (
                    <div
                      className={ProductStyle.filterPanel}
                      style={styles.filterGebruikOptionPanel}
                    >
                      <Descriptions
                        column={1}
                        title={appConstants.filterOptions.Usage.title}
                        colon={false}
                      >
                        {appConstants.filterOptions.Usage.options.map(
                          (item, i) => (
                            <Descriptions.Item
                              key={i}
                              label={
                                <Checkbox
                                  onChange={handleCheckBoxChange(
                                    appConstants.filterOptions.Usage.name,
                                    item
                                  )}
                                  checked={
                                    state[appConstants.filterOptions.Usage.name]
                                      ? state[
                                          appConstants.filterOptions.Usage.name
                                        ].findIndex(el => el === item) >= 0
                                      : false
                                  }
                                />
                              }
                            >
                              {item}
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions>
                    </div>
                  )}*/}
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <div className={ProductStyle.colHeader}>
            <div className={ProductStyle.bikesGridViewHeader}>
              <span style={{ color: 'red', fontSize: '20px' }}>
                {props.productCategory.name}
              </span>

              {/* <Button>
                <img
                  style={{
                    width: '18px',
                    height: '18px',
                    marginRight: `${isSmallScreenForMobile ? 0 : '10px'}`,
                  }}
                  src={process.env.PUBLIC_URL + '/icons/filter-and-sort.png'}
                  alt={'Sort the result'}
                />
                {isSmallScreenForMobile ? '' : 'Sorteer resultaten'}
              </Button> */}
            </div>
          </div>
          {/*<Row type="flex">
            <Col xs={24} md={24} lg={24}>
              <div className={ProductStyle.adsHeaderBanner}>
                <div className={ProductStyle.adsHeaderImage}>
                  <img
                    src={`${appConstants.frontEndEnvURL}/icons/ProductPage/headerBike.png`}
                    width={'100%'}
                    height={'240px'}
                    alt="Header Bike  Not Found"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 20,
                  }}
                >
                  <ImageButtonComponent
                    width={'270px'}
                    height={'240px'}
                    headerText={'GRAAG EEN E-BIKE TESTEN?'}
                    descriptionText={'Kom gerust eens langs!'}
                    buttonText={'Vind jouw winkel'}
                    imgSrc={'icons/HomePage/currentPromotionImage.png'}
                    buttonTextColor={'#0BB143'}
                  />
                </div>
              </div>
            </Col>
            <Col />
          </Row>*/}{/*Corona Remove*/}
       {/* <VideoTextComponent headerText={`Meer leren over ${props.productCategory.name}.`} bodyText={'Bekijk onze introductievideo!'} videoUrl={getVideoUrl()}/>*/}
        <ImgTextComponent headerText={getHeadingTekst()} bodyText={getBodyTekst()} imgUrl={getImgUrl()}/>
          <Row
            style={{ backgroundColor: '#f9f9f9' }}

            justify="space-between"
          >
            <Col span={24}>
              <BikeListComponent
                gridStyle={bikeCompGridStyle}
                onMoreInfoBtnClick={id => handleMoreInfoBtnClick(id)}
                bikeData={productsData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
        <Col span={1} />
      </Row>
    </Container>
  )
}
