import React from 'react';
import { Col, Row } from 'antd'
import { useMediaPredicate } from 'react-media-hook'

export default function ImgTextComponent(props){
	const isSmallScreenForMobile = useMediaPredicate('(max-width: 800px)')
	return(  <Row gutter={[{ xs: 12, sm: 16, md: 24 }, 20]}>
		<Col span={24}>
			<Row style={{
				backgroundColor: 'white',
				borderBottom: '3px solid red',
				padding: 20,
				display: 'flex',
				alignItems: 'center',
				flexDirection: isSmallScreenForMobile ? 'column' : 'row'
			}}
			>
				<Col
					className={'bikeCategories'}
					xs={24}
					sm={24}
					md={12}
					lg={13}
					xl={8}
				>
					<img
						style={{ width: '100%', height: window.location.href.includes('/accessoires') ? '200px' : '320px', objectFit: 'cover' }}
						src={props.imgUrl}
						alt={'Image not found'}
					/>				
				</Col>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={11}
					xl={16}
					style={{ padding: 20,paddingLeft:isSmallScreenForMobile ? 0:40}}
				>
					<h1 style={{color:'#ee3143',fontWeight:'bold',fontSize:28, lineHeight: 1.2, marginBottom: '25px'}}>{props.headerText}</h1>
					<p style={{color:'rgba(0, 0, 0, 0.65)',fontSize:16}} dangerouslySetInnerHTML={{ __html: props.bodyText }} />
				</Col>
			</Row>
		</Col>
	</Row>)
}
